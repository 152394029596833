import React, { useState, useRef } from 'react';
import { graphql, PageProps } from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import styled, { css, keyframes } from 'styled-components';
import {
  Description,
  NoImgTopSection,
  DescriptionWrapper,
  Section,
  colors,
  media,
  commonKeyframes,
  easings,
} from '~/styles';
import { useIntersection } from '~/hooks';
import Title from '~/components/Title';
import TextBreaks from '~/components/TextBreaks';
import Layout from '~/components/layout';
import SEO from '~/components/seo';
import emailjs from '@emailjs/browser';

type FormItem = {
  company: string;
  name: string;
  email: string;
  message: string;
};

type FormBody = FormItem & {
  'form-name': string;
  'attach-file': File;
};

const SendStatusArray = ['yet', 'sending', 'sent'] as const;
type SendStatus = typeof SendStatusArray[number];

const EMAIL_VALID_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/;
const FILE_SIZE_LIMIT = 1024 * 1024 * 2; // 2MB

const ContactPage: React.VFC<PageProps> = () => {
  const { t } = useTranslation();
  const FileRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const [formState, setFormState] = useState<FormItem>({
    company: '',
    name: '',
    email: '',
    message: '',
  });
  const [formError, setFormError] = useState<FormItem>({
    company: '',
    name: '',
    email: '',
    message: '',
  });
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState('');
  const [isIntersection, intersectionRef] = useIntersection();
  const [sendStatus, setSendStatus] = useState<SendStatus>('yet');

  const validateForm = () => {
    const newError: FormItem = {
      company: '',
      name: '',
      email: '',
      message: '',
    };
    let isError = false;

    (Object.keys(formState) as (keyof FormItem)[]).forEach((key) => {
      const item = formState[key];

      if (!item || item === '') {
        newError[key] = t('contact.error.require');
        isError = true;
      }

      if (item && key === 'email' && !EMAIL_VALID_REGEX.test(item)) {
        newError[key] = t('contact.error.email');
        isError = true;
      }
    });
    setFormError(newError);

    if (file && file.size > FILE_SIZE_LIMIT) {
      setFileError(t('contact.error.fileSize'));
      isError = true;
    }

    return isError;
  };

  const onClickFileButton = () => {
    if (FileRef && FileRef.current) {
      FileRef.current.click();
    }
  };

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (!files || files.length <= 0) {
      return;
    }

    const currentFile = files[0];

    if (currentFile.size > FILE_SIZE_LIMIT) {
      setFileError(t('contact.error.fileSize'));
    } else {
      setFileError('');
    }

    setFile(currentFile);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    const data = {
      ...formState,
      'form-name': 'contact',
      'attach-file': file,
    };

    if (validateForm()) {
      return;
    }

    (Object.keys(data) as (keyof FormBody)[]).forEach((key) => {
      const item = data[key];
      if (item !== null) {
        formData.append(key, item);
      }
    });

    try {
      setSendStatus('sending');
      const serviceId = 'amazon_ses';
      const templateId = 'safehouse2021';
      const userId = 'user_cMB9tSesGrVDSlqEWIvT9';
      await emailjs.sendForm(serviceId, templateId, formRef.current!, userId);
      setSendStatus('sent');
    } catch (err) {
      setSendStatus('yet');
      throw new Error(`${err}`);
    }
  };

  return (
    <>
      <SEO title="Contact" />
      <Layout>
        <NoImgTopSection>
          <Title title="contact.title" byKey isIntersection />
        </NoImgTopSection>
        <Section ref={intersectionRef}>
          <DescriptionWrapper
            showAnim
            isIntersection={isIntersection}
            delay={0.6}
          >
            <StyledDescription>
              <Trans i18nKey="contact.text" components={TextBreaks} />
            </StyledDescription>
            <Note>
              <Trans i18nKey="careers.note" components={TextBreaks} />
            </Note>
          </DescriptionWrapper>
          <FormWrapper>
            <Form
              name="contact"
              method="post"
              ref={formRef}
              encType="multipart/form-data"
              onSubmit={onSubmit}
              noValidate
              isSent={sendStatus === 'sent'}
              isSending={sendStatus === 'sending'}
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="contact-form" />
              <FormContentWrapper isIntersection={isIntersection}>
                <input type="hidden" name="form-name" value="contact" />

                <noscript>
                  <p>This form won’t work with Javascript disabled.</p>
                </noscript>
                <Input isError={formError.company !== ''}>
                  <label htmlFor="company">
                    {t('contact.form.company')}
                    <Required>*</Required>
                  </label>
                  <InputText
                    type="text"
                    required
                    name="company"
                    id="company"
                    placeholder="your company inc."
                    onChange={(e) => {
                      setFormState({ ...formState, company: e.target.value });
                      if (formError.company !== '' && e.target.value !== '') {
                        setFormError({ ...formError, company: '' });
                      }
                    }}
                  />
                  <ErrorText isError={formError.company !== ''}>
                    {formError.company}
                  </ErrorText>
                </Input>
                <Input isError={formError.name !== ''}>
                  <label htmlFor="name">
                    {t('contact.form.name')}
                    <Required>*</Required>
                  </label>
                  <InputText
                    type="text"
                    required
                    name="name"
                    id="name"
                    placeholder="your name"
                    onChange={(e) => {
                      setFormState({ ...formState, name: e.target.value });
                      if (formError.name !== '' && e.target.value !== '') {
                        setFormError({ ...formError, name: '' });
                      }
                    }}
                  />
                  <ErrorText isError={formError.name !== ''}>
                    {formError.name}
                  </ErrorText>
                </Input>
                <Input isError={formError.email !== ''}>
                  <label htmlFor="email">
                    {t('contact.form.email')}
                    <Required>*</Required>
                  </label>
                  <InputText
                    type="email"
                    name="email"
                    id="email"
                    placeholder="yourmail@example.com"
                    onChange={(e) => {
                      setFormState({ ...formState, email: e.target.value });
                      if (
                        formError.email !== '' &&
                        e.target.value !== '' &&
                        EMAIL_VALID_REGEX.test(e.target.value)
                      ) {
                        setFormError({ ...formError, email: '' });
                      }
                    }}
                  />
                  <ErrorText isError={formError.email !== ''}>
                    {formError.email}
                  </ErrorText>
                </Input>
                <Input isError={formError.message !== ''}>
                  <label htmlFor="message">
                    {t('contact.form.message')}
                    <Required>*</Required>
                  </label>
                  <TextArea
                    name="message"
                    id="message"
                    required
                    placeholder="message here"
                    onChange={(e) => {
                      setFormState({ ...formState, message: e.target.value });
                      if (formError.message !== '' && e.target.value !== '') {
                        setFormError({ ...formError, message: '' });
                      }
                    }}
                  />
                  <ErrorText isError={formError.message !== ''}>
                    {formError.message}
                  </ErrorText>
                </Input>
                <FileInput>
                  <p>{t('contact.form.attachment')}</p>
                  <FileInputWrapper>
                    <FileButton type="button" onClick={onClickFileButton}>
                      {t('contact.form.fileButton')}
                    </FileButton>
                    <InputText
                      type="file"
                      name="my_file"
                      id="file"
                      onChange={onChangeFile}
                      ref={FileRef}
                    />
                    <FileInputTextWrapper>
                      <FileInputText>
                        {file ? file.name : t('contact.form.noFile')}
                        <ErrorText isError={fileError !== ''} isFile>
                          {fileError}
                        </ErrorText>
                      </FileInputText>
                    </FileInputTextWrapper>
                  </FileInputWrapper>
                </FileInput>
              </FormContentWrapper>
              <ButtonWrapper isIntersection={isIntersection}>
                <Button type="submit">
                  {sendStatus === 'yet' ? (
                    t('contact.form.submit')
                  ) : (
                    <>
                      {t('contact.form.sending')} <Dot>.</Dot>
                      <Dot>.</Dot>
                      <Dot>.</Dot>
                    </>
                  )}
                </Button>
              </ButtonWrapper>
            </Form>
            <SuccessMessage isSent={sendStatus === 'sent'}>
              <SuccessMessageText>
                {t('contact.form.success')}
              </SuccessMessageText>
              <SuccessMessageAttention>
                {t('contact.form.pleaseWait')}
              </SuccessMessageAttention>
            </SuccessMessage>
          </FormWrapper>
        </Section>
      </Layout>
    </>
  );
};

const Note = styled.p`
  font-size: 0.85rem;
  max-width: calc(782px - 50px);
  //max-width: 782px;
  margin-inline: auto;
  margin-top: -2em;
`;

const StyledDescription = styled(Description)`
  text-align: center;
  word-break: keep-all;
  white-space: pre-wrap;
`;

const FormWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 782px;
`;

const Form = styled.form<{ isSent: boolean; isSending: boolean }>`
  text-align: center;
  display: grid;
  opacity: 1;
  transition: opacity 0.3s ${easings.easeOutCubic};

  width: 100%;

  ${media.mdUp} {
    grid-gap: 88px;
  }

  ${media.mdDown} {
    grid-gap: 64px;
  }

  ${({ isSending }) =>
    isSending &&
    css`
      opacity: 0.7;
    `}

  ${({ isSent }) =>
    isSent &&
    css`
      animation: ${commonKeyframes.fadeOut} 0.6s ${easings.easeOutCubic}
        forwards;
    `}
`;

const FormContentWrapper = styled.div<{ isIntersection: boolean }>`
  display: grid;
  grid-gap: 56px;
  opacity: 0;

  ${media.mdDown} {
    grid-gap: 40px;
  }

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic} 1.05s
        forwards;
    `}
`;

const Input = styled.div<{ isError?: boolean }>`
  position: relative;
  display: grid;
  text-align: left;
  grid-gap: 16px;
  font-size: 16px;
  padding-bottom: 8px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0 auto;
    height: 1px;
  }

  &::before {
    width: 100%;
    right: 0;
    background-color: ${colors.white};
  }

  &::after {
    width: 0;
    background-color: ${colors.red};
    transition: width 0.6s ${easings.easeOutCubic};
  }

  ${({ isError }) =>
    isError &&
    css`
      &::after {
        transition-duration: 0.3s;
        width: 100%;
      }
    `}

  ${media.mdDown} {
    grid-gap: 14px;
    font-size: 14px;
    padding-bottom: 4px;
  }
`;

const ErrorText = styled.p<{ isError: boolean; isFile?: boolean }>`
  content: '';
  position: absolute;
  left: 0;
  bottom: -32px;
  margin: 0 auto;
  font-size: 12px;
  width: 100%;
  color: ${colors.red};
  opacity: 0;
  transition: opacity 0.6s ${easings.easeOutCubic};

  ${media.mdDown} {
    bottom: -24px;
  }

  ${({ isError }) =>
    isError &&
    css`
      transition-duration: 0.15s;
      opacity: 1;
    `}

  ${({ isFile }) =>
    isFile &&
    css`
      bottom: -24px;
    `}
`;

const FileInput = styled.div`
  position: relative;
  display: grid;
  text-align: left;
  grid-gap: 16px;
  font-size: 16px;

  &:invalid {
    box-shadow: none;
  }

  ${media.mdDown} {
    grid-gap: 14px;
    font-size: 14px;
    padding-bottom: 4px;
  }
`;

const Required = styled.span`
  color: ${colors.red};
  margin-left: 0.5em;
`;

const InputText = styled.input`
  width: 100%;
  outline: none;
  color: ${colors.white} !important;
  line-height: 1.5;
  font-size: 16px;

  &:invalid {
    box-shadow: none;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px ${colors.black} inset;
    -webkit-text-fill-color: ${colors.white};
  }
  &[type='file'] {
    display: none;
  }

  ${media.mdDown} {
    font-size: 14px;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 10em;
  font-size: 16px;
  outline: none;
  resize: none;
  color: ${colors.white};

  &:invalid {
    box-shadow: none;
  }

  ${media.mdDown} {
    font-size: 14px;
  }
`;

const FileInputWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 24px;
  align-items: center;

  ${media.mdDown} {
    grid-template-columns: auto;
    grid-gap: 16px;
  }
`;

const FileInputTextWrapper = styled.div`
  display: relative;
`;

const FileInputText = styled.p`
  position: relative;
`;

const FileButton = styled.button`
  font-size: 16px;
  padding: 10px 38px;
  min-width: 180px;
  border-radius: 999px;
  border: 1px solid ${colors.white};
  cursor: pointer;
  transition: transform 0.6s ${easings.easeOutCubic};

  ${media.mdDown} {
    text-align: center;
    font-size: 14px;
    padding: 10px 24px;
    max-width: 150px;
    min-width: none;
  }

  &:hover {
    transition-duration: 0.3s;
    transform: scale(0.96);
  }
`;

const ButtonWrapper = styled.div<{ isIntersection: boolean }>`
  text-align: center;
  color: ${colors.black};
  opacity: 0;

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic} 1.8s
        forwards;
    `}
`;

const Button = styled.button`
  display: inline-block;
  line-height: 1.25;
  font-size: 16px;
  padding: 18px 38px;
  min-width: 180px;
  border-radius: 999px;
  background-color: ${colors.white};
  cursor: pointer;
  transition: transform 0.6s ${easings.easeOutCubic};

  ${media.mdDown} {
    font-size: 14px;
    padding: 14px 24px;
    min-width: 140px;
  }

  &:hover {
    transform: scale(0.96);
    transition-duration: 0.3s;
  }
`;

const SuccessMessage = styled.div<{ isSent?: boolean }>`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  grid-gap: 16px;
  text-align: center;
  user-select: none;
  pointer-events: none;
  opacity: 0;

  ${({ isSent }) =>
    isSent &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic} forwards;
      user-select: auto;
      pointer-events: auto;
    `}
`;

const SuccessMessageText = styled.h3`
  font-weight: normal;
  font-size: 40px;
  margin: 0;
  padding: 0;

  ${media.mdDown} {
    font-size: 20px;
  }
`;

const SuccessMessageAttention = styled.p`
  font-size: 16px;

  ${media.mdDown} {
    font-size: 14px;
  }
`;

const dot1 = keyframes`
  25% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const dot2 = keyframes`
  50% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const dot3 = keyframes`
  75% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Dot = styled.span`
  animation: 4.8s infinite;
  opacity: 0;

  &:nth-child(1) {
    animation-name: ${dot1};
  }
  &:nth-child(2) {
    animation-name: ${dot2};
  }
  &:nth-child(3) {
    animation-name: ${dot3};
  }
`;

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
